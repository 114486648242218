<template>
  <ul class="menu-nav">
    <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        class="menu-item menu-item-submenu"
        data-menu-toggle="hover"
        v-bind:class="{
        'menu-item-open': hasActiveChildren('/content-management')
      }"
    >
      <a class="menu-link menu-toggle" href="#">
        <i class="menu-icon flaticon2-layers-1"></i>
        <span class="menu-text">Content Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Content Management</span>
            </span>
          </li>
          <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/content-management/menus"
          >
            <li
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                aria-haspopup="true"
                class="menu-item"
                data-menu-toggle="hover"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Menus</span>
              </a>
            </li>
          </router-link>
          <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/content-management/pages"
          >
            <li
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                aria-haspopup="true"
                class="menu-item"
                data-menu-toggle="hover"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pages</span>
              </a>
            </li>
          </router-link>
          <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/content-management/banners"
          >
            <li
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                aria-haspopup="true"
                class="menu-item"
                data-menu-toggle="hover"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Banners</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
        aria-haspopup="true"
        class="menu-item menu-item-submenu"
        data-menu-toggle="hover"
        v-bind:class="{
        'menu-item-open': hasActiveChildren('/products-management')
      }"
    >
      <a class="menu-link menu-toggle" href="#">
        <i class="menu-icon flaticon2-layers-1"></i>
        <span class="menu-text">Products Management</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Products Management</span>
            </span>
          </li>
          <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/products-management/categories"
          >
            <li
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                aria-haspopup="true"
                class="menu-item"
                data-menu-toggle="hover"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Categories</span>
              </a>
            </li>
          </router-link>
          <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/products-management/products"
          >
            <li
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                aria-haspopup="true"
                class="menu-item"
                data-menu-toggle="hover"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Products</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
<!--    <router-link-->
<!--        v-if="$store.state.auth.user.is_primary == 1"-->
<!--        to="/users"-->
<!--        v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--    >-->
<!--      <li-->
<!--          aria-haspopup="true"-->
<!--          data-menu-toggle="hover"-->
<!--          class="menu-item"-->
<!--          :class="[-->
<!--          isActive && 'menu-item-active',-->
<!--          isExactActive && 'menu-item-active'-->
<!--        ]"-->
<!--      >-->
<!--        <a :href="href" class="menu-link" @click="navigate">-->
<!--          <i class="menu-icon flaticon2-user"></i>-->
<!--          <span class="menu-text">Users</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->
<!--    <router-link-->
<!--        v-if="$store.state.auth.user.is_primary == 1"-->
<!--        to="/settings"-->
<!--        v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--    >-->
<!--      <li-->
<!--          aria-haspopup="true"-->
<!--          data-menu-toggle="hover"-->
<!--          class="menu-item"-->
<!--          :class="[-->
<!--          isActive && 'menu-item-active',-->
<!--          isExactActive && 'menu-item-active'-->
<!--        ]"-->
<!--      >-->
<!--        <a :href="href" class="menu-link" @click="navigate">-->
<!--          <i class="menu-icon flaticon2-settings"></i>-->
<!--          <span class="menu-text">Settings</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->
<!--    <router-link-->
<!--        v-if="$store.state.auth.user.is_primary == 1"-->
<!--        to="/inquiries"-->
<!--        v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--    >-->
<!--      <li-->
<!--          aria-haspopup="true"-->
<!--          data-menu-toggle="hover"-->
<!--          class="menu-item"-->
<!--          :class="[-->
<!--          isActive && 'menu-item-active',-->
<!--          isExactActive && 'menu-item-active'-->
<!--        ]"-->
<!--      >-->
<!--        <a :href="href" class="menu-link" @click="navigate">-->
<!--          <i class="menu-icon flaticon2-layers-1"></i>-->
<!--          <span class="menu-text">Inquiries</span>-->
<!--        </a>-->
<!--      </li>-->
<!--    </router-link>-->
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
